import { srConfig } from "@config";
import { usePrefersReducedMotion } from "@hooks";
import sr from "@utils/sr";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const StyledAboutSection = styled.section`
  /* max-width: 900px; */

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 250px));
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: "▹";
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  /* max-width: 300px; */

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      /* mix-blend-mode: multiply; */
      /* filter: grayscale(100%) contrast(1); */
      /* transition: var(--transition); */
    }

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      /* background-color: var(--navy);
      mix-blend-mode: screen; */
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    "10+ Years of Experience ",
    "10 Team Members",
    "50+ Websites Created",
    "50+ Clients",
    "100+ Marketing Campaigns ",
    "Clients in 5 Countries",
  ];

  return (
    <StyledAboutSection id="who-we-are" ref={revealContainer}>
      <h2 className="numbered-heading">Who We Are</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Mohsin Consulting LLC was founded in New York, USA, and is now an
              international company serving clients in many industries around
              the world.
            </p>

            <p>
              We have become well known for creating high-quality websites for
              businesses. We now also provide digital marketing services such as
              Facebook post boosting and Google SEO page ranking. A
              well-designed website and proper social media marketing are
              crucial in today's digital world, and we are proud to have helped
              many businesses achieve success through these services.
            </p>

            <p>By the numbers: </p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/who-we-are.jpg"
              width={500}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
